import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

loadProgressBar();

/* function App_beta() {

  const [site_name, setSitename] = useState('')
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [url_reset, setUrlReset] = useState('')
  const [templateList, setTemplatesList] = useState([])

  const url_sso = url_reset.replace('resetpwd', 'welcome');

  useEffect(() => {
    Axios.get('http://localhost:3001/templates').then((response) => {
      setTemplatesList(response.data)
      console.log(response)
    })
  }, [])

  function selectTemplate(e) {
    e.preventDefault();
    var template_id = e.currentTarget.dataset.id;
    //setTemplate(template_id);
    if (!e.currentTarget.dataset.id == "") {
      Axios.post('http://localhost:3001/create-site', {
        id: template_id,
      })
        .then((res) => {
          console.log('Site Criado: ', template_id, res);
          setSitename(res.data['site_name']);
          Axios.post('http://localhost:3001/create-site', {
            site_name: res.data['site_name'],
          })
          document.getElementsByClassName('all_templates')[0].style = 'display:none';
          document.getElementsByClassName('create-user')[0].style = 'display:block';
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    }
  }

  const submitData = () => {
    Axios.post('http://localhost:3001/accounts/create', {
      nome: nome,
      sobrenome: sobrenome,
      email: email,
      phone: phone,
      site_name: site_name,
    }).then(() => {
      console.log('Cadastro inserido com sucesso');
      Axios.post('http://localhost:3001/access-site', {
        email: email,
        site_name: site_name,
      }).then(() => {
        console.log('Site Atribuído')
        Axios.post('http://localhost:3001/reset-password', {
          email: email,
        }).then((res) => {
          console.log('SSO sendo gerado', res.data)
          setUrlReset(res.data['reset_url']);
          document.getElementsByClassName('create-user')[0].style = 'display:none';
          document.getElementsByClassName('url')[0].style = 'display:block';
        })
      })
    })

  };
  return (
    <div className="App">
      <header className="header">
        <img src="https://irp-cdn.multiscreensite.com/-resellers-preview/filipe@agenciasantafe.com.br/logo/38dl0r3q7qqc824t6ua2fujbj2.png" />
      </header>
      <Container component="main" fixed>
        <div className="titlePage">
          <h1>Experimente Grátis</h1>
          <p>Não é necessário cartão de crédito</p>
        </div>
        <div className="all_templates">
          <p><b>Selecione um tema para o seu site.</b> Não se preocupe depois você poderá alterar o tema.</p>
          <Grid className="gridTemplates" container spacing={3}>
            {templateList.map((val) => {
              return (
                <Grid className="gridItem" item xs={12} sm={3}>
                  <img src={val.thumbnail_url} width="100%" />
                  <div className="contentTemplate">
                    <h3>{val.template_name}</h3>
                    <Button variant="contained" onClick={selectTemplate} data-id={val.template_id}>Comece a construir</Button>
                    <Button href={val.preview_url} target="_blank">Pré-Visualização</Button>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
        <div className="create-user">
          <p><b>Agora, crie sua conta.</b> Utilize um e-mail válido pois iremos enviar o seu acesso por e-mail.</p>
          <form className="new-customer" noValidade autoComplete="off">
            <TextField className="bigField" id="standard-basic" label="Primeiro Nome" onChange={(e) => {
              setNome(e.target.value)
            }} />
            <TextField className="bigField" id="standard-basic" label="Sobrenome" onChange={(e) => {
              setSobrenome(e.target.value)
            }} />
            <TextField className="bigField" id="standard-basic" label="E-mail" onChange={(e) => {
              setEmail(e.target.value)
            }} />
            <TextField className="bigField" id="standard-number" label="Telefone" onChange={(e) => {
              setPhone(e.target.value)
            }} />
            <Button className="submit" color="primary" variant="contained" onClick={submitData}>Comece já</Button>
          </form>
        </div>
        <div className="url">
          <p><a href={url_sso} target="_blank">Clique Aqui</a></p>
        </div>
      </Container>
    </div>
  );
} */


function App() {

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.falomipage.com.br">
          FALOMI|Page
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  function HeaderApp() {
    return (
      <header className="header">
        <img src="https://irp-cdn.multiscreensite.com/-resellers-preview/filipe@agenciasantafe.com.br/logo/38dl0r3q7qqc824t6ua2fujbj2.png" alt="logotipo Falomi Page" />
      </header>
    );
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(http://falomipage.com.br/images/icons/banner-bg-3.png)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));


  const [site_name, setSitename] = useState('')
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [url_reset, setUrlReset] = useState('')
  const [response_email, setResponse] = useState('')
  const [templateList, setTemplatesList] = useState([])

  const url_sso = url_reset.replace('resetpwd', 'welcome');

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL+'/templates').then((response) => {
      setTemplatesList(response.data)
      console.log(response)
    })
  }, [])

  const submitData = () => {
    Axios.post(process.env.REACT_APP_API_URL+'/accounts/create', {
      nome: nome,
      sobrenome: sobrenome,
      email: email,
      phone: phone,
    }).then(() => {
      console.log('Cadastro inserido com sucesso');
      document.getElementsByClassName('create-user')[0].style = 'display:none';
      document.getElementsByClassName('all_templates')[0].style = 'display:block';
    })
  };

  function selectTemplate(e) {
    e.preventDefault();
    var template_id = e.currentTarget.dataset.id;
    //setTemplate(template_id);
    if (!e.currentTarget.dataset.id == "") {
      Axios.post(process.env.REACT_APP_API_URL+'/create-site', {
        id: template_id,
      })
        .then((res) => {
          console.log('Site Criado: ', template_id, res);
          setSitename(res.data['site_name']);
          const site_name = res.data['site_name']
          Axios.post(process.env.REACT_APP_API_URL+'/access-site', {
            email: email,
            site_name: site_name,
          }).then(() => {
            console.log('Site Atribuído')
            Axios.post(process.env.REACT_APP_API_URL+'/reset-password', {
              email: email,
            }).then((res) => {
              console.log('SSO sendo gerado', res.data)
              setUrlReset(res.data['reset_url']);
              const url_sso = res.data['reset_url'].replace('resetpwd', 'welcome');
              Axios.post(process.env.REACT_APP_API_URL+'/welcome-mail', {
                nome: nome,
                email: email,
                url_sso: url_sso,
              }).then((res) => {
                console.log(res.data)
                const response_email = res.data;
                setResponse(response_email);
                if (response_email == "ok") {
                  window.location.href = "https://www.falomipage.com.br/?v=success";
                }
                //document.getElementsByClassName('all_templates')[0].style = 'display:none';
                //document.getElementsByClassName('url')[0].style = 'display:block'
              })
            })
          })
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    }
  };

  const classes = useStyles();

  return (
    <div className="App">
      <div className="create-user">
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={12} sm={12} md={12}><HeaderApp /></Grid>
          <Grid item xs={false} sm={4} md={7} className={classes.image}>
            <img src="https://du-cdn.multiscreensite.com/duda_website/img/developers/widget-builder/widget_builder_02_1x.png" alt="animação plataforma" width="100%" />
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <div className="titlePage">
                <h1>Experimente Grátis</h1>
                <p>Não é necessário cartão de crédito</p>
              </div>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="nome"
                  label="Nome"
                  name="nome"
                  autoComplete="nome"
                  autoFocus
                  onChange={(e) => {
                    setNome(e.target.value)
                  }} />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="sobrenome"
                  label="Sobrenome"
                  id="sobrenome"
                  autoComplete="Sobrenome"
                  onChange={(e) => {
                    setSobrenome(e.target.value)
                  }} />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="E-mail"
                  id="email"
                  autoComplete="E-mail"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }} />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="telefone"
                  label="Telefone"
                  id="telefone"
                  autoComplete="Telefone"
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }} />
                <p>Ao clicar em "Comece Agora", você concorda com nossos <a href="https://www.falomipage.com.br" target="_blank" rel="noreferrer">Termos de Serviço</a> e <a href="https://www.falomipage.com.br" target="_blank" rel="noreferrer">Política de Privacidade</a>.</p>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={submitData}
                >
                  Comece Agora
            </Button>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="all_templates">
        <HeaderApp />
        <Container component="main" fixed>
          <h2>Selecione um tema para o seu site.</h2>
          <p>Não se preocupe, caso mude de idéia você poderá alterar o tema depois.</p>
          <Grid className="gridTemplates" container spacing={3}>
            {templateList.map((val) => {
              return (
                <Grid className="gridItem" item xs={12} sm={3}>
                  <img src={val.thumbnail_url} width="100%" alt={val.template_name} />
                  <div className="contentTemplate">
                    <h3>{val.template_name}</h3>
                    <Button variant="contained" onClick={selectTemplate} data-id={val.template_id}>Comece a construir</Button>
                    <Button className="theme_view" href={val.preview_url} target="_blank">Pré-Visualização</Button>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </div>
      <Container component="main" fixed>
        <div className="url">
          <p>{response_email}</p>
        </div>
      </Container>
    </div>
  );
}

export default App;
